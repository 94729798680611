import resolveImage from "./resolveImage";

const resolveAudiogram = _a => {
  const a = _a?.[0] || _a;
  const { caption, fullName, shortName, image, audio, title, transcript } = a;
  return {
    ...a,
    heading: title,
    audiograms: [
      {
        audio: audio?.[0]?.url,
        image: resolveImage(image),
        fullName,
        shortName,
        caption,
        transcript: transcript?.[0],
      },
    ],
    transcript: a.transcript?.[0],
  };
};

export default resolveAudiogram;
